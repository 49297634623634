var render = function render(){var _vm=this,_c=_vm._self._c;return _c('x-modal-center',{attrs:{"bilingual":true,"apply-button-text":"save","cancel-button-text":"cancel","footer-class":"d-flex justify-content-between x-gap-1","apply-button-class":"w-100","cancel-button-class":"w-100","apply-button-loading":_vm.applyButtonLoading,"modal-container-style":{
    'max-width': '960px',
    'max-height': '720px',
    width: '75%',
    height: '100%',
    overflowY: 'scroll',
  }},on:{"close":_vm.closeCreatingModal,"cancel":_vm.closeCreatingModal,"apply":_vm.saveItem},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"x-font-size-36px font-craftworksans color-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("AddLanguage"))+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('h3',{staticClass:"mt-4 mb-2 status-pick-color-title"},[_vm._v(" "+_vm._s(_vm.$t("Fill data"))+" ")]),_c('validation-observer',{ref:"creating-observer",staticClass:"client-type-creating-body"},[_c('validation-provider',{ref:"clientTypeNameVProvider",staticClass:"title-uz-provider",attrs:{"name":"last-name-uz-provider","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('x-form-input',{staticClass:"w-100",attrs:{"type":"text","placeholder":_vm.$t('name')},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),(errors[0])?_c('span',{staticClass:"error__provider"},[_vm._v(" "+_vm._s(errors[0].replace("last-name-uz-provider", _vm.$t("title")))+" ")]):_vm._e()]}}])}),_c('div',{staticClass:"mb-3"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.item.is_default),callback:function ($$v) {_vm.$set(_vm.item, "is_default", $$v)},expression:"item.is_default"}},[_vm._v(" "+_vm._s(_vm.$t("is_default"))+" ")])],1),_c('div',{staticClass:"mb-3"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.item.is_published),callback:function ($$v) {_vm.$set(_vm.item, "is_published", $$v)},expression:"item.is_published"}},[_vm._v(" "+_vm._s(_vm.$t("is_published"))+" ")])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }